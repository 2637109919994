import { ReactElement, cloneElement, JSXElementConstructor } from 'react';
import Head from 'next/head';
import Stl from '@root/lib/Stl';

import style from './AppHeaderLayout.module.scss';

const st = (new Stl()).bind(style);

import Header from '@app/Header/Header';
import Footer from '@website/Footer/Footer';
import { useRouter } from 'next/router';

interface Props {
  children: ReactElement<any, string | JSXElementConstructor<any>>
}

export default function AppHeaderLayout(props: Props): ReactElement {
  const router = useRouter();

  const isDashboard = (router.asPath.indexOf('/dashboard') === 0);

  return (
    <>
      <Head>
        <script type="text/javascript" src="https://maps.google.com/maps/api/js?key=AIzaSyAjb8GLflKqFOFq6VwDe-1xZ_re0EV8n9w&libraries=location" />
      </Head>
      <Header />
      <main className={ st`page-container ${ `page-container--${ isDashboard ? 'dashboard' : 'public' }` }` }>
        { cloneElement(props.children)}
      </main>
      { router.asPath.indexOf('/dashboard/listings/') === -1 &&
        <Footer />
      }
    </>
  );
}
