export const userSteps = [
  {
    heading: 'Create an account',
    content: 'Visit YourDesq and create an account for free. You will find an endless list of professional workplaces.',
  },
  {
    heading: 'Search for listings',
    content: 'Log in to your account and search for workplaces in your preferred area. Select the number of users and choose your date.',
  },
  {
    heading: 'Choose a place to work',
    content: 'Pick your preferred workplace, select a type of workspace and request your reservation. Your host will process the booking as soon as possible!',
  },
  {
    heading: 'Pay and check in',
    content: 'To offer you ultimate flexibility, you pay per use. Once your booking has been accepted, you are good to go!',
  },
];

export const hostSteps = [
  {
    heading: 'Register and advertise for free',
    content: 'Sign up and share your space by creating a listing for free. Start by setting up basic information and payment details, then add unlimited listings to your account.',
  },
  {
    heading: 'Create and customize your listings',
    content: "Choose your workspace types and available amenities. Set your office rules and prices, don't forget to include some eye-catching photos!",
  },
  {
    heading: 'Welcome professionals',
    content: 'Accept booking requests and start welcoming professionals. In your account you will always find an overview of your bookings and the possibility to contact your users.',
  },
];
