import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper/core';
import Steps from '../Steps/Steps';
import SectionIntro from '../Section/Intro';
import Section from '../Section/Section';
import styles from './HowItWorks.module.scss';
import { userSteps, hostSteps } from './constants';

import 'swiper/components/pagination/pagination.min.css';

SwiperCore.use([ Pagination ]);

type Props = {
  imagePath: string;
  title: string;
  subtitle: string;
  intendedFor: 'host' | 'user';
}

export default function HowItWorksSection(props: Props): React.ReactElement {
  const steps = props.intendedFor === 'user' ? userSteps : hostSteps;

  const swiperSlides = steps.map((step, index) =>
    <SwiperSlide key={ 'step-'+ index }>
      <Steps
        height="292px"
        imagePath={ props.imagePath }
        items={ [ step ] }
        slides={ { slideIndex: index } }
      />
    </SwiperSlide>
  );

  return (
    <>
      <Section>
        <div className={ `container ${ styles.mobile }` }>
          <SectionIntro
            title={ props.title }
            subtitle={ props.subtitle }
            alignLeft
          />
          <Swiper
            pagination={ { bulletActiveClass: 'secondary-darker' } }
            autoHeight={ true }
          >
            { swiperSlides }
          </Swiper>
        </div>

        <div className={ `container ${ styles.desktop }` }>
          <SectionIntro
            title={ props.title }
            subtitle={ props.subtitle }
            alignLeft
          />
          <Steps
            height="464px"
            imagePath={ props.imagePath }
            items={ steps }
          />
        </div>
      </Section>
    </>
  );
}
